lc-line-list {
  p-slider {
    width: 100%;
  }

  .line-info-container {
    width: 100%;
  }

  .line-name {
    width: 100%;
  }

  .line-link {
    color: inherit;
    text-decoration: inherit;

    &.ticked {
      background-color: var(--ticked-bg-color);
    }
  }

  .bottom-row {
    display: flex;
    width: 100%;

    .video-button {
      button {
        margin-left: -1.25rem;
      }
    }

    lc-tick-button {
      margin-right: -1.25rem;
    }
  }

  .slider-container {
    margin-left: 0.5715rem;
    margin-right: 0.5715rem;
  }

  .archived {
    background-color: #ddd;

    .line-name {
      color: var(--p-surface-700) !important;
    }

    .p-rating-icon {
      color: inherit;
    }

    img {
      filter: grayscale(60%);
    }
  }
}
