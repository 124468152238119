<ng-container *transloco="let t; read: 'scale.scaleForm'">
  <div class="content">
    <p-card header="{{ editMode ? t('editScale') : t('createTitle') }}">
      <form [formGroup]="scaleForm" lcForm>
        <p-card>
          <ng-template #title>
            <ng-container *ngIf="editMode"
              >{{ scale?.lineType | transloco }} {{ scale?.name }}
            </ng-container>
          </ng-template>
          <div class="grid grid-nogutter">
            <ng-container *ngIf="!editMode">
              <div class="col-12 flex gap-4 align-items-center mb-2">
                <label for="linetype">
                  {{ t("lineTypeLabel") }}
                </label>
                <p-select
                  id="linetype"
                  formControlName="lineType"
                  [options]="[
                    { name: t('BOULDER'), value: LineType.BOULDER },
                    { name: t('SPORT'), value: LineType.SPORT },
                    { name: t('TRAD'), value: LineType.TRAD },
                  ]"
                  optionLabel="name"
                />
              </div>
              <div
                class="col-12 flex gap-4 align-items-center mb-2"
                lcControlGroup
              >
                <label for="name">
                  {{ t("nameInputLabel") }}
                </label>
                <input
                  type="text"
                  pInputText
                  id="name"
                  formControlName="name"
                  lcFormControl
                />
                <small class="lc-error" *lcIfError="'required'">
                  {{ t("required") }}
                </small>
              </div>
              <hr class="col-12" />
            </ng-container>
            <div
              class="col-12"
              formArrayName="grades"
              *ngFor="let grade of gradeControls().controls; let i = index"
              lcControlGroup
            >
              <div [formGroupName]="i" class="flex gap-4 mb-2">
                <ng-container
                  *ngIf="
                    grade.get('value').value <= 0 &&
                    ['CLOSED_PROJECT', 'OPEN_PROJECT', 'UNGRADED'].includes(
                      grade.get('name').value
                    )
                  "
                >
                  <div>
                    <label>
                      {{ t("gradeNameLabel") }}
                      <input
                        type="text"
                        class="ml-2"
                        pInputText
                        disabled
                        value="{{
                          grade.get('name').value | translateSpecialGrades
                        }}"
                      />
                    </label>
                  </div>
                  <div>
                    <label>
                      {{ t("gradeValueLabel") }}
                      <input
                        type="text"
                        class="ml-2"
                        pInputText
                        disabled
                        value="{{
                          grade.get('value').value | translateSpecialGrades
                        }}"
                      />
                    </label>
                  </div>
                  <p-button
                    icon="pi pi-trash"
                    styleClass="p-button-danger"
                    disabled
                  />
                </ng-container>
                <ng-container
                  *ngIf="
                    grade.get('value').value > 0 ||
                    !['CLOSED_PROJECT', 'OPEN_PROJECT', 'UNGRADED'].includes(
                      grade.get('name').value
                    )
                  "
                >
                  <div>
                    <!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
                    <label>
                      {{ t("gradeNameLabel") }}
                      <input
                        type="text"
                        class="ml-2"
                        pInputText
                        formControlName="name"
                      />
                    </label>
                  </div>
                  <div>
                    <!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
                    <label>
                      {{ t("gradeValueLabel") }}
                      <p-inputNumber
                        styleClass="ml-2"
                        formControlName="value"
                        [min]="1"
                      />
                    </label>
                  </div>
                  <p-button
                    [disabled]="loadingState === LoadingState.LOADING"
                    icon="pi pi-trash"
                    styleClass="p-button-danger"
                    (click)="deleteGrade(i)"
                  />
                </ng-container>
              </div>
            </div>
            <div class="col-12 mt-2">
              <p-message
                *ngIf="gradeControls().hasError('not_unique')"
                severity="error"
                text="{{ t('valuesNotUnique') }}"
                styleClass="min-w-full mb-3"
              />
              <p-message
                *ngIf="gradeControls().hasError('names_not_filled')"
                severity="error"
                text="{{ t('namesNotFilled') }}"
                styleClass="min-w-full mb-3"
              />
            </div>
            <div class="button-container">
              <p-button
                label="{{ t('addGrade') }}"
                (click)="addGrade()"
                icon="pi pi-plus-circle"
                class="responsive-button"
                [disabled]="loadingState === LoadingState.LOADING"
              />
              <p-button
                label="{{ t('reorderGrades') }}"
                (click)="reorderGradesByValue()"
                icon="pi pi-sort-amount-down-alt"
                class="responsive-button ml-2"
                [disabled]="loadingState === LoadingState.LOADING"
              />
            </div>
          </div>

          <hr class="mt-4" />

          <h4>
            {{ t("gradeBracketsLabel") }} - {{ t("stackedChartBrackets") }}
          </h4>
          <div class="mb-2">
            <em>{{
              t("gradeBracketsDescriptionStackedChart", { min: 2, max: 8 })
            }}</em>
          </div>
          <div
            class="col-12"
            formArrayName="stackedChartBrackets"
            *ngFor="
              let _ of stackedChartBracketsControls().controls;
              let i = index
            "
          >
            <div [formGroupName]="i" class="flex gap-4 mb-2">
              <!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
              <label>
                {{ t("gradeBracketsInputLabel") }}
                <p-inputNumber formControlName="value" [min]="1" class="ml-2" />
                <p-button
                  [disabled]="loadingState === LoadingState.LOADING"
                  icon="pi pi-trash"
                  styleClass="p-button-danger ml-4"
                  (click)="deleteStackedChartBracket(i)"
                />
              </label>
            </div>
          </div>

          <p-message
            *ngIf="gradeControls().hasError('not_unique')"
            severity="error"
            text="{{ t('valuesNotUnique') }}"
            styleClass="min-w-full mb-3"
          />
          <p-message
            *ngIf="stackedChartBracketsControls().hasError('semantic_error')"
            severity="error"
            text="{{ t('gradeBracketsErrorMsg') }}"
            styleClass="min-w-full mb-3"
          />
          <p-message
            *ngIf="stackedChartBracketsControls().hasError('invalid_length')"
            severity="error"
            text="{{ t('gradeBracketsInvalidLength', { min: 2, max: 8 }) }}"
            styleClass="min-w-full mb-3"
          />

          <div class="button-container">
            <p-button
              label="{{ t('addBracket') }}"
              (click)="addStackedChartBracket()"
              icon="pi pi-plus-circle"
              class="responsive-button"
              [disabled]="loadingState === LoadingState.LOADING"
            />
            <p-button
              label="{{ t('reorderGrades') }}"
              (click)="reorderStackedChartBracketsByValue()"
              icon="pi pi-sort-amount-down-alt"
              class="responsive-button ml-2"
              [disabled]="loadingState === LoadingState.LOADING"
            />
          </div>

          <hr class="mt-4" />

          <h4>{{ t("gradeBracketsLabel") }} - {{ t("barChartBrackets") }}</h4>
          <div class="mb-2">
            <em>{{
              t("gradeBracketsDescriptionBarChart", { min: 2, max: 14 })
            }}</em>
          </div>

          <div
            class="col-12"
            formArrayName="barChartBrackets"
            *ngFor="let _ of barChartBracketsControls().controls; let i = index"
          >
            <div [formGroupName]="i" class="flex gap-4 mb-2">
              <!-- eslint-disable-next-line @angular-eslint/template/label-has-associated-control -->
              <label>
                {{ t("gradeBracketsInputLabel") }}
                <p-inputNumber formControlName="value" [min]="1" class="ml-2" />
                <label class="ml-3">
                  {{ t("barChartBracketNameLabel") }}
                  <input
                    type="text"
                    class="ml-2"
                    pInputText
                    formControlName="name"
                  />
                </label>
                <p-button
                  icon="pi pi-trash"
                  [disabled]="loadingState === LoadingState.LOADING"
                  styleClass="p-button-danger ml-4"
                  (click)="deleteBarChartBracket(i)"
                />
              </label>
            </div>
          </div>

          <p-message
            *ngIf="barChartBracketsControls().hasError('not_unique')"
            severity="error"
            text="{{ t('valuesNotUnique') }}"
            styleClass="min-w-full mb-3"
          />
          <p-message
            *ngIf="barChartBracketsControls().hasError('semantic_error')"
            severity="error"
            text="{{ t('gradeBracketsErrorMsg') }}"
            styleClass="min-w-full mb-3"
          />
          <p-message
            *ngIf="barChartBracketsControls().hasError('invalid_length')"
            severity="error"
            text="{{ t('gradeBracketsInvalidLength', { min: 2, max: 14 }) }}"
            styleClass="min-w-full mb-3"
          />
          <p-message
            *ngIf="barChartBracketsControls().hasError('names_not_filled')"
            severity="error"
            text="{{ t('namesNotFilled') }}"
            styleClass="min-w-full mb-3"
          />

          <div class="button-container">
            <p-button
              label="{{ t('addBracket') }}"
              (click)="addBarChartBracket()"
              icon="pi pi-plus-circle"
              class="responsive-button"
              [disabled]="loadingState === LoadingState.LOADING"
            />
            <p-button
              label="{{ t('reorderGrades') }}"
              (click)="reorderBarChartBracketsByValue()"
              icon="pi pi-sort-amount-down-alt"
              class="responsive-button ml-2"
              [disabled]="loadingState === LoadingState.LOADING"
            />
          </div>
        </p-card>

        <p-toolbar styleClass="mt-3 justify-content-end">
          <p-button
            label="{{ t('saveScale') }}"
            (click)="saveScale()"
            icon="pi pi-save"
            class="responsive-button"
            [loading]="loadingState === LoadingState.LOADING"
          />
          <p-button
            label="{{ t('deleteScale') }}"
            (click)="confirmDeleteScale($event)"
            icon="pi pi-trash"
            styleClass="p-button-danger"
            class="responsive-button ml-2"
            [disabled]="loadingState === LoadingState.LOADING"
          />
          <p-button
            label="{{ t('cancel') }}"
            (click)="router.navigate(['/scales'])"
            icon="pi pi-ban"
            severity="secondary"
            class="responsive-button ml-2"
            [disabled]="loadingState === LoadingState.LOADING"
          />
          <p-confirmPopup></p-confirmPopup>
        </p-toolbar>
      </form>
    </p-card>
  </div>
</ng-container>
