@use "../../../styles/media-queries" as m;

// Add styles for search dialog prime ng component
.search-dialog .p-dialog-content {
  padding-top: 1.25rem;
}

.search-dialog {
  margin-top: 5rem !important;

  @media (screen and max-width: 800px) {
    margin-top: 0 !important;
    height: 100%;
    max-height: 100%;

    &.p-dialog {
      border-radius: 0 !important;
    }
  }
}

// Add internal styles
lc-search-dialog {
  display: block;
  width: 40rem;
  max-width: 100%;

  .spacer {
    display: block;
    width: 100%;
    height: 1.5rem;
  }

  lc-searchable .search-result {
    border-radius: 0.5rem;

    &:hover {
      cursor: pointer;
      background-color: var(--p-gray-100);
    }
  }
}
