lc-archive-button {
  button > span {
    display: flex;
  }

  p-button.disabled {
    opacity: 1 !important;

    button:disabled {
      opacity: 1 !important;
    }
  }
}
