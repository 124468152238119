lc-todo-button {
  button > span {
    display: flex;
  }

  .hide-label .p-button-label {
    display: none;
  }

  .is-todo-icon {
    background-color: var(--p-blue-500);
    border-radius: 0.25rem;
    padding: 0.1rem;
    color: white;
  }
}
