<ng-container *transloco="let t; read: 'footer'">
  <div class="footer-menu" *ngIf="menuItems.length > 0">
    <ng-container *ngFor="let menuItem of menuItems">
      <p-button
        [dt]="footerButtonsDt"
        *ngIf="menuItem.routerLink"
        label="{{ menuItem.title }}"
        styleClass="p-button-secondary p-button-text"
        routerLink="{{ menuItem.routerLink }}"
      >
      </p-button>
      <p-button
        [dt]="footerButtonsDt"
        *ngIf="menuItem.link"
        label="{{ menuItem.title }}"
        styleClass="p-button-secondary p-button-text"
        (click)="openLink(menuItem.link)"
      >
      </p-button>
    </ng-container>
  </div>
  <div class="footer-notes">
    <div class="footer-note-item">
      {{ t("copyright") }} © {{ currentYear }} {{ copyrightOwner$ | async }}
    </div>
    <div class="footer-note-item">
      Powered with 🦾 by
      <a href="https://github.com/LocalCrag/LocalCragApp" target="_blank"
        >LocalCrag</a
      >
      <span class="version"> v{{ version }} </span>
    </div>
  </div>
</ng-container>
