<ng-container *transloco="let t; read: 'coordinatesButton'">
  <p-split-button
    label="{{ t('coordinates') }}"
    icon="pi pi-map-marker"
    text
    (onClick)="openCoordinates()"
    [model]="items"
  >
  </p-split-button>
</ng-container>
