<ng-container *transloco="let t; read: 'sector.sectorList'">
  <p-dataView
    #dv
    [value]="sectors"
    [sortField]="sortField"
    [sortOrder]="sortOrder"
  >
    <ng-template #emptymessage>
      <p-message
        severity="info"
        icon="pi pi-info-circle"
        class="m-4 mb-1 block"
        >{{ t("noSectorsFoundEmptyMessage") }}</p-message
      >
    </ng-template>
    <ng-template #header>
      <div class="flex flex-column sm:flex-row justify-content-between gap-2">
        <p-select
          [options]="sortOptions"
          [(ngModel)]="sortKey"
          optionLabel="label"
          (onChange)="onSortChange($event)"
          [disabled]="loading === loadingStates.LOADING"
          styleClass="md:mb-0"
        >
          <ng-template #selectedItem>
            <div class="flex align-items-center gap-2" *ngIf="sortKey">
              <i [class]="sortKey.icon" *ngIf="sortKey.icon"></i>
              <div>{{ sortKey.label }}</div>
            </div>
          </ng-template>
          <ng-template let-option #item>
            <div class="flex align-items-center gap-2">
              <i [class]="option.icon" *ngIf="option.icon"></i>
              <div>{{ option.label }}</div>
            </div>
          </ng-template>
        </p-select>
        <div class="flex justify-content-start sm:justify-content-end gap-3">
          <p-button
            label="{{ t('newSectorButtonLabel') }}"
            icon="pi pi-plus"
            class="responsive-button"
            [disabled]="loading === loadingStates.LOADING"
            routerLink="/topo/{{ cragSlug }}/create-sector"
            *isModerator="true"
          ></p-button>
          <p-button
            label="{{ t('reorderSectorsButtonLabel') }}"
            icon="pi pi-sort"
            (click)="reorderSectors()"
            [disabled]="
              sectors?.length < 2 || loading === loadingStates.LOADING
            "
            class="responsive-button"
            *isModerator="true"
          ></p-button>
        </div>
      </div>
    </ng-template>
    <ng-template let-sectors #list>
      <lc-topo-dataview-skeleton
        *ngIf="loading === loadingStates.LOADING"
      ></lc-topo-dataview-skeleton>
      <div class="grid grid-nogutter" *ngIf="loading !== loadingStates.LOADING">
        <a
          class="col-12 clickable-data-item"
          routerLink="/topo/{{ cragSlug }}/{{ sector.slug }}/areas"
          *ngFor="let sector of sectors; let first = first"
          data-cy="sector-list-item"
        >
          <div
            class="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4"
            [ngClass]="{ 'border-top-1 surface-border': !first }"
          >
            <img
              class="w-9 sm:w-16rem xl:w-10rem shadow-2 block xl:block mx-auto border-round"
              [src]="
                sector.portraitImage
                  ? sector.portraitImage.thumbnailM
                  : 'assets/placeholder.png'
              "
              [alt]="sector.name"
            />
            <div
              class="flex flex-column sm:flex-row justify-content-between align-items-center xl:align-items-start flex-1 gap-4"
            >
              <div
                class="flex flex-column align-items-center sm:align-items-start gap-3 w-full"
              >
                <div class="flex justify-content-between w-full">
                  <span class="text-2xl font-bold black-font">
                    {{ sector.name }}
                  </span>
                  <lc-archive-button
                    *isModerator="true"
                    [showLabel]="true"
                    [sector]="sector"
                  />
                </div>
                <div
                  [innerHTML]="sector.shortDescription | sanitizeHtml"
                  *ngIf="sector.shortDescription"
                  class="quill-content"
                ></div>
                <lc-leveled-grade-distribution
                  [fetchingObservable]="
                    sectorsService.getSectorGrades(sector.slug)
                  "
                ></lc-leveled-grade-distribution>
                <lc-ascent-count
                  [ascentCount]="sector.ascentCount"
                ></lc-ascent-count>
                <div class="flex gap-1">
                  <lc-closed-spot-tag
                    *ngIf="sector.closed"
                  ></lc-closed-spot-tag>
                  <lc-secret-spot-tag
                    *ngIf="sector.secret"
                  ></lc-secret-spot-tag>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </ng-template>
  </p-dataView>
</ng-container>
