{
  "name": "localcrag-client",
  "version": "1.16.1",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "start:cypress-test-backend": "cd ../server/src && export PYTHONPATH=. && export LOCALCRAG_CONFIG=config/test-ci-e2e.cfg && pipenv run flask run",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "test:ci": "ng test --no-watch --no-progress --code-coverage --browsers=ChromeHeadless",
    "i18n:extract": "transloco-keys-manager extract -d \"\" -l de",
    "i18n:find": "transloco-keys-manager find",
    "cypress": "cypress open",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org localcrag --project localcrag-client ./dist/client && sentry-cli sourcemaps upload --org localcrag --project localcrag-client ./dist/client",
    "lint": "ng lint"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.2.2",
    "@angular/cdk": "^19.2.3",
    "@angular/common": "^19.2.2",
    "@angular/compiler": "^19.2.2",
    "@angular/core": "^19.2.2",
    "@angular/forms": "^19.2.2",
    "@angular/platform-browser": "^19.2.2",
    "@angular/platform-browser-dynamic": "^19.2.2",
    "@angular/router": "^19.2.2",
    "@jsverse/transloco": "^7.5.1",
    "@jsverse/transloco-keys-manager": "^6.1.0",
    "@ngneat/until-destroy": "^10.0.0",
    "@ngrx/effects": "^19.0.1",
    "@ngrx/store": "^19.0.1",
    "@ngrx/store-devtools": "^19.0.1",
    "@primeng/themes": "^19.0.9",
    "@sentry/angular": "^9.3.0",
    "@sentry/cli": "^2.42.2",
    "@types/geojson": "^7946.0.16",
    "@types/maplibre-gl": "^1.14.0",
    "chart.js": "^4.4.1",
    "chartjs-plugin-datalabels": "^2.2.0",
    "date-fns": "^3.3.1",
    "jwt-decode": "^4.0.0",
    "konva": "^9.3.18",
    "maplibre-gl": "^5.1.1",
    "ngx-infinite-scroll": "^19.0.0",
    "ngx-mapbox-gl": "^11.0.1",
    "ngx-matomo-client": "^7.0.1",
    "primeflex": "^4.0.0",
    "primeicons": "^7.0.0",
    "primeng": "^19.0.9",
    "quill": "^1.3.7",
    "quill-blot-formatter": "^1.0.5",
    "quill-image-uploader": "^1.3.0",
    "rxjs": "~7.8.2",
    "tslib": "^2.8.1",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.2.3",
    "@angular-eslint/builder": "19.2.1",
    "@angular-eslint/eslint-plugin": "19.2.1",
    "@angular-eslint/eslint-plugin-template": "19.2.1",
    "@angular-eslint/schematics": "19.2.1",
    "@angular-eslint/template-parser": "19.2.1",
    "@angular/cli": "~19.2.3",
    "@angular/compiler-cli": "^19.2.2",
    "@types/jasmine": "~4.0.0",
    "@types/mapbox-gl": "^3.1.0",
    "@typescript-eslint/eslint-plugin": "7.11.0",
    "@typescript-eslint/parser": "7.11.0",
    "cypress": "^13.6.6",
    "eslint": "^8.57.0",
    "jasmine-core": "~4.3.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.0.0",
    "lint-staged": "^15.4.3",
    "prettier": "^3.3.3",
    "typescript": "~5.8.2"
  },
  "lint-staged": {
    "**/*.{js,ts}": [
      "prettier --write .",
      "eslint"
    ],
    "src/assets/i18n/**/*.{json}": [
      "prettier --write ."
    ]
  }
}
