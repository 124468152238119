@use "../../../styles/media-queries" as m;

lc-footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: var(--p-surface-300);

  .version {
    color: var(--p-surface-500);
  }

  .p-button-label {
    color: var(--p-surface-700);
    font-weight: normal;
  }

  .footer-menu {
    display: block;
    padding: 0.5rem;
  }

  .footer-notes {
    display: flex;
    align-items: center;
    margin-left: 6rem;
    margin-right: 6rem;
    justify-content: center;
    font-size: 12px;
    color: var(--p-surface-700);
    border-top: 1px solid var(--p-surface-400);
    width: calc(100% - 12rem);
    box-sizing: border-box;

    @include m.on-mobile {
      margin-left: 0;
      margin-right: 0;
      width: 100%;
    }

    .footer-note-item {
      padding: 1rem;
      display: inline-block;

      a,
      a:visited,
      a:hover,
      a:active {
        color: var(--p-surface-700);
      }
    }
  }
}
