lc-todo-priority-button {
  p-button .p-button-label {
    white-space: nowrap;
  }

  .high-priority .pi {
    color: red;
  }

  .medium-priority .pi {
    color: blue;
  }

  .low-priority .pi {
    color: grey;
  }
}
