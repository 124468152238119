<div class="p-4 flex gap-4 flex-column">
  <div class="flex gap-2" *ngFor="let _ of [].constructor(10)">
    <p-skeleton
      width="100%"
      height="3rem"
      styleClass="mr-2"
      class="block w-full"
    ></p-skeleton>
  </div>
</div>
