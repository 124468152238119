<ng-container *transloco="let t; read: 'gallery.galleryForm'">
  <div class="grid">
    <div class="col-12 flex justify-content-end" *isLoggedIn="true">
      <p-button
        (click)="addImage()"
        icon="pi pi-image"
        [label]="t('addImage')"
      ></p-button>
    </div>

    <div class="col-12 md:col-6 xl:col-4" *ngFor="let image of images">
      <lc-gallery-image
        [image]="image"
        (delete)="confirmDeleteImage($event, image)"
        (edit)="editImage(image)"
      ></lc-gallery-image>
    </div>
    <div
      class="col-12 md:col-6 xl:col-4"
      *ngFor="
        let _ of !(
          loadingAdditionalPage === loadingStates.LOADING ||
          loadingFirstPage === loadingStates.LOADING
        )
          ? [1, 2, 3]
          : [1, 2, 3, 4, 5, 6]
      "
    >
      <lc-gallery-image-skeleton></lc-gallery-image-skeleton>
    </div>
    <div class="flex justify-content-center mt-5" *ngIf="hasNextPage">
      <p-button
        label="{{ t('loadMore') }}"
        (scrolled)="loadNextPage()"
        (click)="loadNextPage()"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="50"
        [loading]="loadingAdditionalPage === loadingStates.LOADING"
      ></p-button>
    </div>
  </div>
  <p-message
    severity="info"
    *ngIf="
      !(
        loadingAdditionalPage === loadingStates.LOADING ||
        loadingFirstPage === loadingStates.LOADING
      ) && images.length === 0
    "
  >
    {{ t("thisGalleryIsEmpty") }}
  </p-message>
  <p-confirmPopup></p-confirmPopup>
</ng-container>
