<ng-container *transloco="let t; read: 'searchDialog'">
  <p-inputgroup>
    <p-inputgroup-addon>
      <i class="pi pi-search" *ngIf="!loading"></i>
      <i class="pi pi-spin pi-spinner" *ngIf="loading"></i>
    </p-inputgroup-addon>
    <input
      type="text"
      pInputText
      [(ngModel)]="query"
      (ngModelChange)="search()"
      placeholder="{{ t('searchInputPlaceholder') }}"
    />
    <p-inputgroup-addon>
      <p-button icon="pi pi-times" (click)="close()"></p-button>
    </p-inputgroup-addon>
  </p-inputgroup>

  <div class="spacer" *ngIf="searchables?.length > 0"></div>
  <lc-searchable
    [searchable]="searchable"
    *ngFor="let searchable of searchables"
  >
  </lc-searchable>
</ng-container>
