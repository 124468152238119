lc-app-level-alerts {
  width: 100%;
  display: block;

  .alert {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: fit-content;
    color: #ffffff;

    a {
      color: #ffffff;
    }

    &.warning {
      background-color: var(--p-yellow-700);
    }

    &.danger {
      background-color: var(--p-red-700);
    }

    &.info {
      background-color: var(--p-blue-700);
    }

    .alert-actions {
      display: inline-block;
    }

    .alert-text {
      display: inline-block;
      padding: 0.5rem;
    }

    .p-button-label {
      color: #fff;
    }
  }
}
