@use "../../../../styles/media-queries" as m;

lc-coordinates-button {
  .p-splitbutton-button {
    padding-left: 0;
    padding-right: 0;
  }

  .p-button-icon-only {
    width: 1.5rem;
    padding-left: 0.5rem;
  }

  .p-tieredmenu {
    @include m.on-mobile {
      top: unset;
      right: 0;
      margin-left: auto;
    }
  }
}
