lc-instance-settings-form {
  p-password {
    width: 100%;

    .p-password {
      width: 100%;

      .p-password-input {
        width: 100%;
      }
    }
  }
}

#gym-mode {
  .p-toggleswitch-slider {
    background-color: var(--p-green-600);
  }

  .p-toggleswitch-checked .p-toggleswitch-slider {
    background-color: var(--p-blue-600);
  }
}
