lc-line {
  .card-header {
    padding: 1.25rem;
    padding-bottom: 0;
  }

  .p-card-body {
    padding-top: 0;
  }

  .grade-font {
    font-weight: normal;
  }
}
