<ng-container *transloco="let t; read: 'ascentForm'">
  <form [formGroup]="ascentForm" lcForm>
    <p-divider align="center" type="dotted">
      <b>{{ t("ascentDate") }}</b>
    </p-divider>

    <div class="flex justify-content-center gap-2 mb-2">
      <div
        class="flex flex-column gap-2 flex-grow-1"
        lcControlGroup
        *ngIf="ascentForm.get('yearOnly').value"
      >
        <p-date-picker
          formControlName="year"
          view="year"
          dateFormat="yy"
          inputId="year"
          lcFormControl
          appendTo="body"
          [maxDate]="today"
          [readonlyInput]="true"
          styleClass="w-full"
        ></p-date-picker>
        <small class="lc-error" *lcIfError="'dateInFuture'">{{
          t("yearInFutureValidationError")
        }}</small>
        <small class="lc-error" *lcIfError="'required'">{{
          t("required")
        }}</small>
      </div>
      <div
        class="flex flex-column gap-2 flex-grow-1"
        lcControlGroup
        *ngIf="!ascentForm.get('yearOnly').value"
      >
        <p-date-picker
          formControlName="date"
          view="date"
          inputId="date"
          lcFormControl
          styleClass="w-full"
          appendTo="body"
          [maxDate]="today"
          [readonlyInput]="true"
          dateFormat="dd.mm.yy"
        ></p-date-picker>
        <small class="lc-error" *lcIfError="'dateInFuture'">{{
          t("dateInFutureValidationError")
        }}</small>
        <small class="lc-error" *lcIfError="'required'">{{
          t("required")
        }}</small>
      </div>
      <p-toggleButton
        formControlName="yearOnly"
        [onLabel]="t('onlyYear')"
        [offLabel]="t('onlyYear')"
        [dt]="toggleButtonDt"
      ></p-toggleButton>
    </div>

    <div class="flex justify-content-center gap-2">
      <p-button
        [label]="t('today')"
        size="small"
        severity="secondary"
        (click)="setToToday()"
      ></p-button>
      <p-button
        [label]="t('lastSaturday')"
        size="small"
        severity="secondary"
        (click)="setToLastSaturday()"
      ></p-button>
      <p-button
        [label]="t('lastSunday')"
        size="small"
        severity="secondary"
        (click)="setToLastSunday()"
      ></p-button>
    </div>

    <p-divider align="center" type="dotted">
      <b>{{ t("personalGrade") }}</b>
    </p-divider>

    <div
      class="flex flex-row gap-2 justify-content-center align-items-center"
      lcControlGroup
    >
      <p-toggleButton
        formControlName="soft"
        [onLabel]="t('soft')"
        [offLabel]="t('soft')"
        [dt]="toggleButtonDt"
      ></p-toggleButton>
      <p-select
        formControlName="grade"
        [options]="grades"
        data-cy="grade-dropdown"
        optionLabel="name"
        placeholder="{{ t('gradePlaceholder') }}"
        lcFormControl
      >
        <ng-template let-grade #item>
          <div
            class="flex align-items-center gap-2"
            data-cy="grade-dropdown-item"
          >
            <div>{{ grade.name | translateSpecialGrades }}</div>
          </div>
        </ng-template>
        <ng-template let-selectedGrade #selectedItem>
          {{ selectedGrade.name | translateSpecialGrades }}
        </ng-template>
      </p-select>
      <p-toggleButton
        formControlName="hard"
        [onLabel]="t('hard')"
        [offLabel]="t('hard')"
        [dt]="toggleButtonDt"
      ></p-toggleButton>
      <small class="lc-error" *lcIfError="'required'">{{
        t("required")
      }}</small>
    </div>
    <p-message
      severity="warn"
      text="{{ t('unusualGradeDifferenceWarning') }}"
      styleClass="ascent-form-alert mt-2"
      *ngIf="gradeDifferenceWarning"
    ></p-message>

    <p-divider align="center" type="dotted">
      <b>{{ t("rating") }}</b>
    </p-divider>

    <div
      class="flex flex-column gap-2 align-items-center justify-content-center"
      lcControlGroup
    >
      <p-rating
        formControlName="rating"
        lcFormControl
        data-cy="rating"
      ></p-rating>
      <small class="lc-error" *lcIfError="'required'">{{
        t("required")
      }}</small>
    </div>

    <p-divider align="center" type="dotted">
      <b>{{ t("comment") }}</b>
    </p-divider>

    <div class="flex flex-row gap-2" lcControlGroup>
      <textarea
        rows="2"
        cols="30"
        pTextarea
        lcFormControl
        formControlName="comment"
        class="w-full"
        data-cy="comment"
      ></textarea>
    </div>

    <p-divider align="center" type="dotted">
      <b>{{ t("otherInfo") }}</b>
    </p-divider>

    <div
      class="flex flex-row gap-2 align-items-center justify-content-center"
      lcControlGroup
    >
      <p-toggleButton
        formControlName="flash"
        [dt]="toggleButtonDt"
        [onLabel]="t('flash')"
        [offLabel]="t('flash')"
      ></p-toggleButton>
      <p-toggleButton
        formControlName="withKneepad"
        [dt]="toggleButtonDt"
        [onLabel]="t('withKneepad')"
        data-cy="withKneepad"
        [offLabel]="t('withKneepad')"
      ></p-toggleButton>
      <p-toggleButton
        formControlName="fa"
        [dt]="toggleButtonDt"
        [onLabel]="t('fa')"
        [offLabel]="t('fa')"
      ></p-toggleButton>
    </div>
    <p-message
      *ngIf="ascentForm.get('fa').value"
      styleClass="mt-2 ascent-form-alert"
      severity="info"
      text="{{ t('firstAscentExplanation') }}"
    >
    </p-message>
  </form>

  <div class="form-actions">
    <p-button
      label="{{
        !editMode ? t('addAscentButtonLabel') : t('editAscentButtonLabel')
      }}"
      (click)="saveAscent()"
      styleClass="w-full"
      [loading]="loadingState === loadingStates.LOADING"
      data-cy="submit"
    ></p-button>
  </div>
</ng-container>
