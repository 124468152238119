lc-gallery-image {
  .info-icon {
    height: 2rem;
    width: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;

    i {
      color: var(--p-gray-500);
    }
  }

  .speed-dial-container {
    position: relative;
    width: 2rem;
    height: 2rem;
  }

  .p-speeddial {
    position: absolute;
    top: 2px; // Button is misaligned for some reason
    left: 0;
    width: 2rem;
    height: 2rem;
  }

  .p-speeddial-button {
    width: 2rem;
    height: 2rem;
    padding: 1rem 0;

    .p-button-icon {
      font-size: 0.75rem;
    }
  }

  .p-speeddial-action {
    width: 2rem;
    height: 2rem !important;
    font-size: 0.75rem;

    .p-speeddial-action-icon {
      font-size: 0.75rem;
    }
  }

  .tags {
    flex-wrap: wrap;
    display: flex;
    justify-content: end;
    gap: 0.25rem;

    p-tag {
      color: white;
      border-radius: calc(2 * var(--p-tag-rounded-border-radius));
      padding: 0.125rem 0.25rem;
    }
  }

  .gallery-image-card {
    overflow: hidden;

    .p-card-header {
      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        object-position: center;
      }

      .image-container {
        height: 300px;
        width: 100%;
        display: flex;

        p-image {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;

          .p-image {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .p-card-header {
      display: flex;
      justify-content: center;
      background-color: #f4f4f4;
    }
  }

  // Revert PrimeNG styles for small screens that remove the shadow and border-radius
  .p-card {
    box-shadow:
      0 2px 1px -1px rgba(0, 0, 0, 0.2),
      0 1px 1px 0 rgba(0, 0, 0, 0.14),
      0 1px 3px 0 rgba(0, 0, 0, 0.12) !important;
    border-radius: 6px !important;
  }
}
