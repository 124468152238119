lc-tag {
  .p-tag {
    background-color: var(--p-gray-400);
    transition: background-color 0.3s ease;
    cursor: pointer;
    padding-left: 0.25rem;

    &:hover {
      background-color: var(--p-gray-500);
    }
  }
}
