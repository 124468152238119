<div class="content">
  <p-card>
    <ng-template #header>
      <div class="card-header p-card-title">
        <p-avatar
          [image]="user.avatar ? user.avatar.thumbnailS : 'assets/user.png'"
          styleClass="mr-3"
          size="xlarge"
          shape="circle"
          *ngIf="user"
        ></p-avatar>
        {{ user?.firstname }} {{ user?.lastname }}
      </div>
    </ng-template>

    <p-tabs
      [value]="tabsDirective.activeTabIndex()"
      scrollable
      lcSetActiveTab
      #tabsDirective="lcSetActiveTab"
      [items]="items"
      *ngIf="user"
    >
      <p-tablist>
        @for (tab of items; track $index) {
          <p-tab
            [value]="$index"
            [routerLink]="tab.routerLink"
            *ngIf="tab.visible"
          >
            <i [class]="tab.icon" style="font-size: 1rem"></i>
            {{ tab.label }}
          </p-tab>
        }
      </p-tablist>
    </p-tabs>

    <router-outlet name="userContent"></router-outlet>
  </p-card>
</div>
