lc-refresh-login-modal {
  p-password {
    width: 100%;
    display: block;
    margin-bottom: 1rem;

    input,
    .p-password {
      width: 100%;
    }
  }
}
