'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _createClass = function () {
  function defineProperties(target, props) {
    for (var i = 0; i < props.length; i++) {
      var descriptor = props[i];
      descriptor.enumerable = descriptor.enumerable || false;
      descriptor.configurable = true;
      if ("value" in descriptor) descriptor.writable = true;
      Object.defineProperty(target, descriptor.key, descriptor);
    }
  }
  return function (Constructor, protoProps, staticProps) {
    if (protoProps) defineProperties(Constructor.prototype, protoProps);
    if (staticProps) defineProperties(Constructor, staticProps);
    return Constructor;
  };
}();
var _BlotFormatter = require('../BlotFormatter');
var _BlotFormatter2 = _interopRequireDefault(_BlotFormatter);
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    default: obj
  };
}
function _classCallCheck(instance, Constructor) {
  if (!(instance instanceof Constructor)) {
    throw new TypeError("Cannot call a class as a function");
  }
}
var Action = function () {
  function Action(formatter) {
    _classCallCheck(this, Action);
    this.formatter = formatter;
  }
  _createClass(Action, [{
    key: 'onCreate',
    value: function onCreate() {}
  }, {
    key: 'onDestroy',
    value: function onDestroy() {}
  }, {
    key: 'onUpdate',
    value: function onUpdate() {}
  }]);
  return Action;
}();
exports.default = Action;