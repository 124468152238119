lc-tick-button {
  button > span {
    display: flex;
  }

  .hide-label .p-button-label {
    display: none;
  }

  p-button.disabled {
    opacity: 1 !important;

    button:disabled {
      opacity: 1 !important;
    }
  }

  .ticked-icon {
    background-color: var(--ticked-color);
    border-radius: 0.25rem;
    padding: 0.1rem;
    color: white;
  }
}
