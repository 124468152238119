@mixin container-650 {
  @container (max-width: 650px) {
    @content;
  }
}

lc-topo-image-list {
  .line-number {
    background-color: var(--arrow-color);
    color: var(--arrow-text-color);
    border-radius: 0.25rem;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
  }

  .line-table {
    width: 100%;
    border-top: 1px solid var(--p-surface-200);
    border-bottom: 1px solid var(--p-surface-200);
    padding-bottom: 0.5rem;
    container-type: inline-size;

    .table-header {
      background-color: var(--p-surface-50);
      font-weight: 700;
      color: var(--p-surface-700);
      padding: 0.5rem;
    }
  }

  .archived {
    background-color: #ddd;

    .line-table .table-header {
      background-color: inherit;
    }

    .p-rating-icon {
      color: inherit;
    }

    canvas {
      filter: grayscale(60%);
    }
  }
}

lc-topo-image-list {
  p-rating {
    padding: 0.75rem 0;
  }

  .line-table-wrapper {
    .line-table-row {
      display: flex;
      color: inherit;
      text-decoration: inherit;
      border-top: 1px solid var(--p-surface-200);
      padding-top: 0.25rem;
      padding-bottom: 0.25rem;

      &.ticked {
        background-color: var(--ticked-bg-color);
      }

      @media (hover: hover) and (pointer: fine) {
        // Disables tap hover effect on mobile
        &:hover {
          cursor: pointer;

          .line-number {
            background-color: var(--arrow-highlight-color);
            color: var(--arrow-highlight-text-color);
          }
        }
      }

      .line-table-column-number {
        flex-basis: 2rem;
        display: flex;
        justify-content: left;
        align-items: center;
      }

      .line-table-column-info {
        flex-basis: calc(100% - 2rem);
        display: flex;
        flex-wrap: wrap;

        .line-table-column-info-top-row {
          flex-basis: calc(100% - 16rem);
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .line-table-info-name {
            font-weight: 500;
            flex-basis: 40%;
            display: flex;
            justify-content: left;
            align-items: center;
            hyphens: auto;
            overflow-wrap: anywhere;
          }

          .line-table-info-grade {
            font-weight: 500;
            display: flex;
            justify-content: left;
            align-items: center;
            text-align: left;
            flex-basis: 30%;

            .grade-ellipsis {
              max-width: min-content;
            }
          }

          .line-table-info-style {
            display: flex;
            align-items: center;
            flex-basis: 30%;
            padding-right: 0.5rem;
          }
        }

        .line-table-column-info-bottom-row {
          flex-basis: 16rem;
          display: flex;
          flex-direction: row;
          overflow: hidden;

          .line-table-info-rating {
            display: flex;
            align-items: center;
            flex-basis: calc(100% - 12rem);
          }

          .line-table-info-actions {
            display: flex;
            flex-direction: row;
            flex-basis: 12rem;
            justify-content: right;
            align-items: center;

            p-button {
              margin-right: -0.75rem;
            }
          }
        }
      }

      @include container-650 {
        .line-table-column-number {
          flex-basis: 2rem;
          display: flex;
          align-items: flex-start;
          padding-top: 0.125rem;
        }
        .line-table-column-info {
          flex-basis: calc(100% - 2rem);
          display: flex;
          flex-wrap: wrap;

          .line-table-column-info-top-row {
            padding-top: 0.125rem;
            flex-basis: 100%;
            display: flex;
            flex-direction: row;

            .line-table-info-name {
              font-weight: bold;
              flex-basis: calc(100% - 8rem);
              display: flex;
              align-items: center;
            }

            .line-table-info-grade {
              font-weight: 500;
              display: flex;
              justify-content: right;
              text-align: right;
              flex-basis: 8rem;

              .grade-ellipsis {
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                max-width: 7rem;
              }
            }

            .line-table-info-style {
              margin-top: 0.5rem;
              font-size: 10px;
              padding-right: 0;
              flex-basis: 100%;
            }
          }

          .line-table-column-info-bottom-row {
            flex-basis: 100%;
            display: flex;
            flex-direction: row;

            .line-table-info-rating {
              display: flex;
              align-items: center;
              flex-basis: calc(100% - 11rem);
            }

            .line-table-info-actions {
              display: flex;
              flex-direction: row;
              flex-basis: 11rem;
              justify-content: right;

              p-button {
                margin-right: -0.75rem;
              }
            }
          }
        }
      }
    }
  }
}
