<ng-container *transloco="let t; read: 'gallery.galleryForm'">
  <p-message severity="info">
    <ng-container *ngIf="!editMode">
      <div [innerHTML]="t('addGalleryImageDescription')"></div>
    </ng-container>
    <ng-container *ngIf="editMode">
      <div [innerHTML]="t('editGalleryImageDescription')"></div>
    </ng-container>
  </p-message>

  <form [formGroup]="galleryImageForm" lcForm>
    <div class="flex flex-column gap-2" lcControlGroup>
      <label class="form-label" for="image">{{ t("galleryImageLabel") }}</label>
      <lc-single-image-upload
        lcFormControl
        id="image"
        formControlName="image"
        data-cy="topo-image-input"
      ></lc-single-image-upload>
      <small class="lc-error" *lcIfError="'required'">{{
        t("required")
      }}</small>
    </div>

    <div class="flex flex-column gap-2" lcControlGroup>
      <label class="form-label" for="image">{{ t("tagsLabel") }}</label>
      <p-autoComplete
        lcFormControl
        formControlName="searchables"
        [appendTo]="'body'"
        [unique]="true"
        [dataKey]="'id'"
        [panelStyleClass]="'searchable-autocomplete'"
        [placeholder]="t('tagsPlaceholder')"
        [optionLabel]="'name'"
        [suggestions]="searchablesSuggestions"
        (completeMethod)="loadTagSuggestions($event)"
        [multiple]="true"
      >
        <ng-template let-searchable #item>
          <lc-searchable [searchable]="searchable"></lc-searchable>
        </ng-template>
      </p-autoComplete>
      <small class="lc-error" *lcIfError="'required'">{{
        t("min1TagRequired")
      }}</small>
    </div>
  </form>

  <div class="form-actions">
    <p-button
      label="{{
        !editMode
          ? t('addGalleryImageButtonLabel')
          : t('editGalleryImageButtonLabel')
      }}"
      (click)="saveGalleryImage()"
      styleClass="w-full"
      [loading]="loadingState === loadingStates.LOADING"
      data-cy="submit"
    ></p-button>
  </div>
</ng-container>
