<div
  class="flex flex-column xl:flex-row xl:align-items-start p-4 gap-4"
  *ngFor="let _ of [].constructor(5)"
>
  <div class="w-12 sm:w-9 xl:w-5 align-self-center">
    <p-skeleton height="20rem"></p-skeleton>
  </div>
  <div class="w-12 sm:w-12 xl:w-7 flex flex-column gap-2">
    <p-skeleton height="3rem"></p-skeleton>
    <p-skeleton height="1.5rem"></p-skeleton>
    <p-skeleton height="1.5rem"></p-skeleton>
    <p-skeleton height="1.5rem"></p-skeleton>
    <p-skeleton height="1.5rem"></p-skeleton>
    <p-skeleton height="1.5rem"></p-skeleton>
  </div>
</div>
