<ng-container *transloco="let t; read: 'gallery.galleryImage'">
  <p-card styleClass="gallery-image-card">
    <ng-template #header>
      <div class="image-container">
        <p-image
          [src]="image.image.thumbnailM"
          [preview]="true"
          [previewImageSrcSet]="image.image.srcSet"
        >
          <ng-template #indicator>
            <i class="pi pi-eye"></i>
          </ng-template>
        </p-image>
      </div>
    </ng-template>
    <div class="tags">
      <div class="info-icon">
        <i
          class="pi pi-info-circle"
          (click)="infoIcon.focus()"
          #infoIcon
          tabindex="0"
          tooltipEvent="focus"
          pTooltip="{{
            t('createdBy', { createdBy: image.createdBy.fullname })
          }}"
          tooltipPosition="top"
        ></i>
      </div>
      <lc-tag [tag]="tag" *ngFor="let tag of image.tags"></lc-tag>
      <div
        class="speed-dial-container"
        *ngIf="
          image.createdBy.id === loggedInUser?.id || loggedInUser?.moderator
        "
      >
        <p-speed-dial
          [model]="speedDialItems"
          direction="up"
          showIcon="pi pi-bars"
          hideIcon="pi pi-times"
        />
      </div>
    </div>
  </p-card>
</ng-container>
