lc-grade-distribution-bar-chart {
  display: block;

  p-chart {
    height: 100%;
    display: block;

    div {
      height: 100%;
      display: block;
    }
  }

  .total {
    font-size: 12px;
    padding-left: 14px;
    padding-top: 14px;
  }
}
