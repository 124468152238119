lc-menu {
  width: 100%;

  .main-link {
    text-decoration: none;
    color: inherit;
  }

  .p-menu-item-link-active {
    .p-menu-item-label,
    .p-menu-item-icon {
      color: var(--p-primary-600) !important;
    }
  }

  p-avatar {
    cursor: pointer;
    display: flex;
  }

  .wide-menu {
    width: 15rem;
  }

  .search-fake-input {
    background: #ffffff;
    border: 1px solid #d1d5db;
    border-radius: 6px;
    display: flex;
    padding: 0.5rem;
    padding-right: 2rem;
    transition:
      background-color 0.2s,
      color 0.2s,
      border-color 0.2s,
      box-shadow 0.2s;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      border-color: #3b82f6;
    }

    @media (screen and max-width: 960px) {
      border: none;
      background: transparent;
      padding-right: 0.5rem;
      .search-label {
        display: none;
      }
    }
  }
}
