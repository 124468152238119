<ng-container *transloco="let t; read: 'sector.sectorForm'">
  <div class="content">
    <p-card
      header="{{ editMode ? t('editSectorTitle') : t('createSectorTitle') }}"
    >
      <p class="mt-0" *ngIf="!editMode">
        {{ t("createSectorDescription") }}
      </p>

      <ng-container *ngIf="sectorForm">
        <form [formGroup]="sectorForm" lcForm>
          <div class="flex flex-column gap-2" lcControlGroup>
            <label class="form-label" for="name">{{
              t("sectorNameLabel")
            }}</label>
            <input
              id="name"
              type="text"
              pInputText
              formControlName="name"
              placeholder="{{ t('sectorNamePlaceholder') }}"
              lcFormControl
              data-cy="sector-form-name"
            />
            <small class="lc-error" *lcIfError="'required'">{{
              t("required")
            }}</small>
            <small class="lc-error" *lcIfError="'maxlength'">{{
              t("maxlength120")
            }}</small>
          </div>

          <div class="flex flex-column gap-2" lcControlGroup>
            <label class="form-label" for="shortDescription">{{
              t("sectorShortDescriptionLabel")
            }}</label>
            <p-editor
              id="shortDescription"
              formControlName="shortDescription"
              lcFormControl
              placeholder="{{ t('sectorShortDescriptionPlaceholder') }}"
              [style]="{ height: '200px' }"
              [readonly]="loadingState === loadingStates.INITIAL_LOADING"
              data-cy="sector-form-shortDescription"
            >
              <ng-template #header>
                <span class="ql-formats">
                  <button
                    type="button"
                    class="ql-bold"
                    aria-label="Bold"
                  ></button>
                  <button
                    type="button"
                    class="ql-italic"
                    aria-label="Italic"
                  ></button>
                  <button
                    type="button"
                    class="ql-underline"
                    aria-label="Underline"
                  ></button>
                </span>
                <span class="ql-formats">
                  <button
                    type="button"
                    class="ql-link"
                    aria-label="Link"
                  ></button>
                </span>
              </ng-template>
            </p-editor>
          </div>

          <div class="flex flex-column gap-2" lcControlGroup>
            <label class="form-label" for="description">{{
              t("sectorDescriptionLabel")
            }}</label>
            <p-editor
              id="description"
              formControlName="description"
              lcFormControl
              placeholder="{{ t('sectorDescriptionPlaceholder') }}"
              [modules]="quillModules"
              data-cy="sector-form-description"
              [style]="{ height: '200px' }"
              [readonly]="loadingState === loadingStates.INITIAL_LOADING"
            >
              <ng-template #header>
                <span class="ql-formats">
                  <button
                    type="button"
                    class="ql-bold"
                    aria-label="Bold"
                  ></button>
                  <button
                    type="button"
                    class="ql-italic"
                    aria-label="Italic"
                  ></button>
                  <button
                    type="button"
                    class="ql-underline"
                    aria-label="Underline"
                  ></button>
                </span>
                <span class="ql-formats">
                  <button
                    type="button"
                    value="ordered"
                    class="ql-list"
                    aria-label="Ordered List"
                  ></button>
                  <button
                    type="button"
                    value="bullet"
                    class="ql-list"
                    aria-label="Unordered List"
                  ></button>
                </span>
                <span class="ql-formats">
                  <button
                    type="button"
                    class="ql-link"
                    aria-label="Link"
                  ></button>
                  <button
                    type="button"
                    class="ql-image"
                    aria-label="Image"
                  ></button>
                  <button
                    type="button"
                    class="ql-video"
                    aria-label="Video"
                  ></button>
                </span>
              </ng-template>
            </p-editor>
          </div>

          <div class="flex flex-column gap-2" lcControlGroup>
            <label class="form-label" for="rules">{{
              t("sectorRulesLabel")
            }}</label>
            <p-editor
              id="rules"
              formControlName="rules"
              lcFormControl
              placeholder="{{ t('sectorRulesPlaceholder') }}"
              [style]="{ height: '200px' }"
              [readonly]="loadingState === loadingStates.INITIAL_LOADING"
              data-cy="sector-form-rules"
            >
              <ng-template #header>
                <span class="ql-formats">
                  <button
                    type="button"
                    class="ql-bold"
                    aria-label="Bold"
                  ></button>
                  <button
                    type="button"
                    class="ql-italic"
                    aria-label="Italic"
                  ></button>
                  <button
                    type="button"
                    class="ql-underline"
                    aria-label="Underline"
                  ></button>
                </span>
                <span class="ql-formats">
                  <button
                    type="button"
                    class="ql-link"
                    aria-label="Link"
                  ></button>
                </span>
              </ng-template>
            </p-editor>
          </div>

          <div class="flex flex-column gap-2" lcControlGroup>
            <label class="form-label" for="portrait-image">{{
              t("sectorPortraitImageLabel")
            }}</label>
            <lc-single-image-upload
              id="portrait-image"
              lcFormControl
              formControlName="portraitImage"
              data-cy="sector-form-portraitImage"
            >
            </lc-single-image-upload>
          </div>

          <div class="flex flex-column gap-2" lcControlGroup>
            <label class="form-label" for="map-markers">{{
              t("mapMarkersLabel")
            }}</label>
            <lc-map-marker-form-array
              id="map-markers"
              formControlName="mapMarkers"
              [disabledMarkerTypes]="disabledMarkerTypesSector"
              lcFormControl
              [defaultMarkerType]="MapMarkerType.SECTOR"
            ></lc-map-marker-form-array>
          </div>

          <div
            class="flex flex-column gap-2"
            lcControlGroup
            *ngIf="sector?.slug !== environment.skippedSlug"
          >
            <label class="form-label">{{ t("sectorOptionsLabel") }}</label>
            <div class="flex flex-column gap-2">
              <div class="flex">
                <p-checkbox
                  inputId="secret"
                  name="secret"
                  [binary]="true"
                  formControlName="secret"
                  lcFormControl
                ></p-checkbox>
                <label [for]="'secret'" class="ml-2">{{
                  t("secretLabel")
                }}</label>
              </div>
              <div class="flex">
                <p-checkbox
                  inputId="closed"
                  name="closed"
                  [binary]="true"
                  formControlName="closed"
                  lcFormControl
                ></p-checkbox>
                <label [for]="'closed'" class="ml-2">{{
                  t("closedLabel")
                }}</label>
              </div>
            </div>
          </div>

          <div class="grid align-items-center">
            <h4 class="col-12">{{ "defaultScalesLabel" | transloco }}</h4>

            <label
              for="defaultBoulderScale"
              class="xl:col-1 md:col-2 sm:col-3 col-4"
            >
              {{ "BOULDER" | transloco }}:
            </label>
            <div class="xl:col-11 md:col-10 sm:col-9 col-8">
              <p-select
                id="defaultBoulderScale"
                [options]="boulderScales"
                optionLabel="label"
                optionValue="value"
                formControlName="defaultBoulderScale"
                lcFormControl
              ></p-select>
            </div>

            <label
              for="defaultSportScale"
              class="xl:col-1 md:col-2 sm:col-3 col-4"
            >
              {{ "SPORT" | transloco }}:
            </label>
            <div class="xl:col-11 md:col-10 sm:col-9 col-8">
              <p-select
                id="defaultSportScale"
                [options]="sportScales"
                optionLabel="label"
                optionValue="value"
                formControlName="defaultSportScale"
                lcFormControl
              ></p-select>
            </div>

            <label
              for="defaultTradScale"
              class="xl:col-1 md:col-2 sm:col-3 col-4"
            >
              {{ "TRAD" | transloco }}:
            </label>
            <div class="xl:col-11 md:col-10 sm:col-9 col-8">
              <p-select
                id="defaultTradScale"
                [options]="tradScales"
                optionLabel="label"
                optionValue="value"
                formControlName="defaultTradScale"
                lcFormControl
              ></p-select>
            </div>
          </div>

          <p-message
            severity="warn"
            icon="pi pi-info-circle"
            *ngIf="parentSecret && !sectorForm.get('secret').value"
          >
            {{ t("aPublicSectorWillSetParentsToPublic") }}
          </p-message>

          <p-message
            severity="warn"
            icon="pi pi-info-circle"
            *ngIf="parentClosed && !sectorForm.get('closed').value"
          >
            {{ t("openingAClosedSectorWillSetParentsToOpen") }}
          </p-message>

          <div
            class="flex flex-column gap-2"
            lcControlGroup
            *ngIf="sectorForm.get('closed').value"
          >
            <label class="form-label" for="closedReason">{{
              t("closedReasonLabel")
            }}</label>
            <input
              id="closedReason"
              type="text"
              pInputText
              formControlName="closedReason"
              placeholder="{{ t('closedReasonPlaceholder') }}"
              lcFormControl
            />
          </div>
        </form>

        <div class="form-actions">
          <p-button
            label="{{ t('createSectorButtonLabel') }}"
            (click)="saveCrag()"
            icon="pi pi-save"
            class="responsive-button"
            [loading]="loadingState === loadingStates.LOADING"
            *ngIf="!editMode"
            data-cy="submit"
          ></p-button>
          <p-button
            label="{{ t('editSectorButtonLabel') }}"
            (click)="saveCrag()"
            icon="pi pi-save"
            class="responsive-button"
            [loading]="loadingState === loadingStates.LOADING"
            *ngIf="editMode"
          ></p-button>
          <p-button
            label="{{ t('cancelButtonLabel') }}"
            (click)="cancel()"
            class="ml-3 responsive-button"
            styleClass="p-button-secondary"
            icon="pi pi-ban"
          ></p-button>
          <p-button
            label="{{ t('deleteSectorButtonLabel') }}"
            (click)="confirmDeleteSector($event)"
            icon="pi pi-trash"
            styleClass="p-button-danger"
            class="ml-3 responsive-button"
            [loading]="loadingState === loadingStates.LOADING"
            *ngIf="editMode && sector?.slug !== environment.skippedSlug"
          ></p-button>
          <p-confirmPopup></p-confirmPopup>
        </div>
      </ng-container>

      <lc-form-skeleton *ngIf="!sectorForm"></lc-form-skeleton>
    </p-card>
  </div>
</ng-container>
