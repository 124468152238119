<div class="content">
  <p-card header="{{ area?.name }}">
    <div class="flex gap-1">
      <lc-closed-spot-tag *ngIf="area?.closed"></lc-closed-spot-tag>
      <lc-secret-spot-tag *ngIf="area?.secret"></lc-secret-spot-tag>
    </div>

    <p-breadcrumb
      class="max-w-full"
      [model]="breadcrumbs"
      [home]="breadcrumbHome"
    ></p-breadcrumb>

    <p-tabs
      [value]="tabsDirective.activeTabIndex()"
      scrollable
      lcSetActiveTab
      #tabsDirective="lcSetActiveTab"
      data-cy="area-tabs-menu"
      [items]="items"
      *ngIf="area"
    >
      <p-tablist>
        @for (tab of items; track $index) {
          <p-tab
            [value]="$index"
            [routerLink]="tab.routerLink"
            *ngIf="tab.visible"
          >
            <i [class]="tab.icon" style="font-size: 1rem"></i>
            {{ tab.label }}
          </p-tab>
        }
      </p-tablist>
    </p-tabs>

    <router-outlet name="areaContent"></router-outlet>
  </p-card>
</div>
